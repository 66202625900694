// preact
import { FunctionComponent } from 'preact'
import { useState, useEffect } from 'preact/hooks'

// components
import Button from 'components/Button'


const TIMEOUT = 60000

const Message: FunctionComponent<{
	type?: 'error' | 'info' | 'success', dismissable?: boolean, onDismiss?: () => void
}> = ({
	type = 'info', dismissable = false, onDismiss, children
}) => {
	const hasChildren = !!children
	const [ isVisible, setIsVisible ] = useState(hasChildren)

	useEffect(() => {
		let timer: number
		if (hasChildren) {
			setIsVisible(true)
			if (type !== 'error') {
				timer = window.setTimeout(() => {
					setIsVisible(false)
					onDismiss && onDismiss()
				}, TIMEOUT)
			}
		}
		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [ hasChildren ])

	const onDismissClick = () => {
		setIsVisible(false)
		onDismiss && onDismiss()
	}

	return isVisible && hasChildren ? <div class="message" role="alert" data-type={type}>
		<p class="message-content">{children}</p>
		{dismissable && <Button importance="tertiary" icon={{ glyph: 'close', position: 'center' }} onClick={onDismissClick}>Dismiss</Button>}
	</div> : null
}

export default Message

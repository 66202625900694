// preact
import { FunctionComponent } from 'preact'
import { useEffect, useState } from 'preact/hooks'

// stores
import UserStore from 'stores/user'
import ProfileStore from 'stores/profile'

// types
import { User, Profile } from 'stores/api/types'

// components
import Page from 'components/Page'
import Header from 'components/Header'
import Button from 'components/Button'
import Input from 'components/Input'
import Select from 'components/Select'
import Tabs from 'components/Tabs'
import Filters from 'components/Filters'
import UserProfile from 'components/UserProfile'

let userHost = window.location.hostname
let parts = userHost.split('.')
if (!window.location.port && parts.length > 1 && parts.reduce((b: boolean, p: string) => b && isNaN(Number(p)), true)) {
	parts.shift()
	userHost = `db.${parts.join('.')}`
} else {
	userHost = `${userHost}:8080`
}
export const USER_HOST = `${window.location.protocol}//${userHost}`

const UserRow: FunctionComponent<{
	user: User, profile: Profile
	checked?: boolean, onChecked?: (id: string, user: User, profile: Profile, checked: boolean) => void
	onAccept?: (id: string, accept: boolean) => void
	onDeactivate?: (id: string) => void
}> = ({
	user, profile,
	checked, onChecked,
	onAccept, onDeactivate
}) => {
	return <tr className="user-row">
		<td>
			<input type="checkbox" checked={!!checked} onClick={onChecked ?
					(e) => onChecked(user.id, user, profile, (e.target as HTMLInputElement).checked) :
					undefined}/>
		</td>
		<th scope="row">
			<a href={`${USER_HOST}/profile/${profile.slug}`} target="_blank">
				<UserProfile user={user} profile={profile}/>
			</a>
		</th>
		<td>
			<p>{user.email}</p>
		</td>
		<td>
			<p>{profile.location.city}</p>
		</td>
		<td>
			<div className="row-actions">
				{user.state === 'verified' ? <>
					<Button quality="good" onClick={onAccept ? () => onAccept(user.id, true) : undefined}>Accept</Button>
					<Button quality="bad" onClick={onAccept ? () => onAccept(user.id, false) : undefined}>Deny</Button>
				</> : <>
					<Button quality={profile.state === 'verified' ? 'bad' : 'good'} icon={{
						glyph: profile.state === 'verified' ? 'unpublished' : 'check_circle'
					}} onClick={onAccept ? () => onAccept(user.id, profile.state !== 'verified') : undefined}
					>{profile.state === 'verified' ? 'Unverify' : 'Verify'}</Button>
					<Button quality="bad" icon={{ glyph: 'delete' }}
							onClick={onDeactivate ? () => onDeactivate(user.id) : undefined}>Deactivate</Button>
				</>}
			</div>
		</td>
	</tr>
}

const Admin: FunctionComponent = () => {
	const userStore = UserStore.use()
	const profileStore = ProfileStore.use()

	const sort = userStore.admin.sort
	const [ type, setType ] = useState<'creative' | 'partner'>(userStore.admin.filters.type)
	const [ search, setSearch ] = useState<string>(userStore.admin.filters.search ?? '')
	const [ state, setState ] = useState<'verified' | 'active'>('verified')
	const [ selections, setSelections ] = useState<Record<string, { user: User, profile: Profile }>>({})
	const ids = Object.keys(selections)

	useEffect(() => {
		setSearch('')
		setSelections({})
		userStore.list({ filters: { type, state, search: '' } })
	}, [ type, state ])

	useEffect(() => {
		userStore.list({ filters: { type, state, search } })
	}, [ search ])

	const onCheckAll = (checked: boolean) => {
		setSelections(checked ? userProfiles.reduce<Record<string, { user: User, profile: Profile }>>((o, e) =>
				(o[e.user.id] = e, o), {}) : {})
	}

	const onCheckOne = (id: string, user: User, profile: Profile, checked: boolean) => {
		const _selections = { ...selections }
		if (checked) {
			_selections[id] = { user, profile }
		} else {
			delete _selections[id]
		}
		setSelections(_selections)
	}

	const onAcceptAll = (accept: boolean) => {
		if (ids.length) {
			switch (state) {
				case 'verified':
					userStore.state({ state: accept ? 'active' : 'pending', ids })
							.then(() => userStore.list({ filters: { type, state, search } }))
					break
				case 'active':
					profileStore.state({ state: accept ? 'verified' : 'pending', ids })
							.then(() => userStore.list({ filters: { type, state, search } }))
					break
			}
		}
	}

	const onAcceptOne = (id: string, accept: boolean) => {
		switch (state) {
			case 'verified':
				userStore.state({ state: accept ? 'active' : 'pending', ids: [ id ] })
						.then(() => userStore.list({ filters: { type, state, search } }))
				break
			case 'active':
				profileStore.state({ state: accept ? 'verified' : 'pending', ids: [ id ] })
						.then(() => userStore.list({ filters: { type, state, search } }))
				break
		}
	}

	const onDeactivate = (id: string) => {
		if (state === 'active') {
			userStore.state({ state: 'verified', ids: [ id ] })
					.then(() => userStore.list({ filters: { type, state, search } }))
		}
	}

	const onSortClick = (field: string, order: boolean) => {
		userStore.list({ sort: { field, order: field !== sort.field ? false : order }, filters: { type, state, search } })
	}

	const onExportClick = (userProfiles: { user: User, profile: Profile }[]) => {
		const blob = new Blob([
			'name\temail\tcity\tprofile_name\tprofile_url\tprofile_categories\tprofile_description\n'
			+ userProfiles.map(r => [
				r.user.name, r.user.email, r.profile.location.city, r.profile.name, `https://db.rottencityculturaldistrict.com/profile/${r.profile.slug}`,
				r.profile.categories.map((c) => c.title).join(','), r.profile.description.replace(/(\r\n|\n|\r|\s+|\t|\\|&nbsp;)/gm, ' ')
			].join('\t')).join('\n')
		], { type: 'text/csv;charset=utf-8;' })

		const link = document.createElement('a')
		link.href = URL.createObjectURL(blob)
		link.download = 'export_' + new Date().toLocaleString('sv') + '.tsv'
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	const status = state === 'verified' ? 'pending' : 'active'
	const userProfiles = userStore.admin.data
	return <>
		<Header title="Admin">
			<Tabs name="status" onSelect={id => setState(id as 'verified' | 'active')}>
				<Tabs.Item id="verified">Pending Users</Tabs.Item>
				<Tabs.Item id="active">Active Users</Tabs.Item>
			</Tabs>
		</Header>
		<Page name="admin">
			<Filters>
				<Select name="view" label={type} target={'users'}
						select={[ type ]} onSelect={id => setType(id[0] as 'creative' | 'partner')}>
					<Select.Option id="creative" value={'Creatives'} current={type === 'creative' ? 'page' : undefined}>Creatives</Select.Option>
					<Select.Option id="partner" value={'Partners'} current={type === 'partner' ? 'page' : undefined}>Partners</Select.Option>
				</Select>
				<div className="filter-selectors">
					{state === 'verified' ? <>
						<Button quality="good" disabled={ids.length < 1} onClick={() => onAcceptAll(true)}>Accept Selected</Button>
						<Button quality="bad" disabled={ids.length < 1} onClick={() => onAcceptAll(false)}>Deny Selected</Button>
					</> : <>
						<Button quality="good" disabled={ids.length < 1} onClick={() => onAcceptAll(true)}>Verify Selected</Button>
						<Button quality="bad" disabled={ids.length < 1} onClick={() => onAcceptAll(false)}>Unverify Selected</Button>
					</>}
					{Object.keys(selections).length ? <>
						<Button importance="primary" quality="good"
								to={`mailto:?bcc=${Object.values(selections).filter(s => s).map(s => encodeURIComponent(s.user.email)).join(',')}`}
						>Email Selected</Button>
						<Button importance="primary" quality="good" onClick={() => onExportClick(Object.values(selections).filter(s => s))}
						>Export Selected</Button>
					</> : <></>}
				</div>
			</Filters>
			<header className="table-header">
				<h3 className="list-title">List Items</h3>
				<p className="list-count">{userProfiles.length} {status.capitalize()} {type.capitalize()}</p>
				<Input id="search" type="search" label={`Search ${status} ${type}`} placeholder={'Search'} value={search} onInput={setSearch}/>
			</header>
			<section class="table" id="users">
				<table>
					<thead>
					<tr>
						<th><input type="checkbox" onClick={(e) => onCheckAll((e.target as HTMLInputElement).checked)}/></th>
						<th>
							<Button importance="tertiary" icon={sort.field === 'name' ? { glyph: `arrow_drop_${sort.order ? 'down' : 'up'}` } : undefined}
									onClick={() => onSortClick('name', !sort.order)}
							>Profile (User) Name</Button>
						</th>
						<th>
							<Button importance="tertiary" icon={sort.field === 'email' ? { glyph: `arrow_drop_${sort.order ? 'down' : 'up'}` } : undefined}
									onClick={() => onSortClick('email', !sort.order)}
							>User Email</Button>
						</th>
						<th>
							<Button importance="tertiary" icon={sort.field === 'city' ? { glyph: `arrow_drop_${sort.order ? 'down' : 'up'}` } : undefined}
									onClick={() => onSortClick('city', !sort.order)}
							>Location</Button>
						</th>
						<th><p>Actions</p></th>
					</tr>
					</thead>
					<tbody>
					{userProfiles.map(({ user, profile }) =>
							<UserRow key={user.id} user={user} profile={profile} checked={!!selections[user.id]} onChecked={onCheckOne}
									 onAccept={onAcceptOne} onDeactivate={onDeactivate}/>
					)}
					</tbody>
				</table>
				{(userProfiles.length < 1) && <div class="empty">No {status.capitalize()} Users</div>}
			</section>
		</Page>
	</>
}

export default Admin
